'use strict';

import { showSomeContent }                        from "./utils/showSomeContent.js";
import { setDeviceType }                          from "./utils/deviceType.js";
import { search }                                 from "./utils/search";
import { languageMenu }                           from "./utils/languageMenu.js";
import { lazyLoadImgs }                           from "./utils/lazyLoad.js";
import { toggleHeader }                           from "./utils/toggleHeader.js";
import { validateForm }                           from "./utils/validate.js";
import { accountFormAddActive, showAccountPopup } from "./utils/account.js";
import {
    getParametersFromWindow, showErrorInConsole
}                                                 from "./utils/helpers";
import { runOtherFuncs }                          from "./utils/runOtherFuncs.js";
import { dynamicLoadContent }                     from "./utils/dynamicLoadContent.js";
import { index_php_key }                          from "./utils/vars.js";

document.addEventListener('DOMContentLoaded', async () : Promise<void> => {
    const windowParameters = getParametersFromWindow();
    let pageType : string = '',
        deviceType : string = '';

    if ('page_type' in windowParameters) {
        pageType = windowParameters['page_type'] as string;
    }

    if ('device_type' in windowParameters) {
        deviceType = windowParameters['device_type'] as string;
    }

    import('./utils/helpers/collapse')
        .then(module => module.handleCollapse())
        .catch(error => showErrorInConsole(error.message));

    if (pageType == 'home') {
        import('./utils/helpers/carousel')
            .then(module => {
                module.handleCarousel({
                    ride: true,
                });
            })
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'cart') {
        import('./utils/shippingContent')
            .then(module => module.handleShipping())
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'category') {
        import('./utils/categorySortList')
            .then(module => module.handleSortList())
            .catch(error => showErrorInConsole(error.message));

        import('./utils/ocfilter')
            .then(module => module.handleOCFilter())
            .catch(error => showErrorInConsole(error.message));

        import('./utils/showPageDescr')
            .then(module => module.showPageDescr())
            .catch(error => showErrorInConsole(error.message));

        import('./utils/moveSomeContent.js')
            .then(module => module.moveSomeContent('.category__top-filter-chosen', '.category__top-filter-chosen-inner'))
            .catch(error => showErrorInConsole(error.message));

        dynamicLoadContent(
            'data-ajax-load-cat-prods',
            index_php_key + 'product/category.loadMoreProducts',
            '.category__products-list .prods__list'
        );
    } else if (pageType == 'product') {
        import('./utils/helpers/carousel')
            .then(module => module.handleCarousel())
            .catch(error => showErrorInConsole(error.message));

        import('./utils/modalProdImageGallery')
            .then(module => module.handleImageGallery(
                '.product__main-imgs-container .carousel-inner',
                '.carousel-item'
            ))
            .catch(error => showErrorInConsole(error.message));

        import('./utils/rating.js')
            .then(module => module.setRating())
            .catch(error => showErrorInConsole(error.message));

        import('./utils/helpers')
            .then(module => module.scrollToAnchor('.product__top-link'))
            .catch(error => showErrorInConsole(error.message));

        import('./utils/productReview.js')
            .then(module => {
                module.loadMoreReviews();
                module.sendProductReview();
                module.sendAnswerForReview();
                module.loadReviewAnswers();
            })
            .catch(error => showErrorInConsole(error.message));

        await import('./utils/loadDynamicIframe.js')
            .then(module => module.loadDynamicIframe())
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'login') {
        import('./utils/login.js')
            .then(module => module.login('.login__form'))
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'login' || pageType == 'product' || pageType == 'cart' || pageType == 'contact') {
        import('./utils/parsePhone.js')
            .then(module => module.parsePhoneNumber())
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType != 'cart') {
        await import('./utils/helpers/offcanvas')
            .then(module => module.handleOffcanvas())
            .catch(error => showErrorInConsole(error.message));

        await import('./utils/cart.js')
            .then(module => {
                module.addToCart();
                module.quantityOperation();
                module.showCartModal();
            })
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'category' || pageType == 'wishlist' || pageType == 'account' || pageType == 'blog') {
        import('./utils/loadMoreContent.js')
            .then(module => {
                module.loadMoreContent('.category__products');
                module.loadMoreContent('.wishlist__products');
                module.loadMoreContent('.account__item--last-orders', false, '#last-orders');
                module.loadMoreContent('.blog-list__articles');
            })
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'home' || pageType == 'category' || pageType == 'product') {
        dynamicLoadContent('data-ajax-load-products', index_php_key + 'extension/some_products/module/products.loadProducts');
    }

    if (pageType == 'home' || pageType == 'category' || pageType == 'product' || pageType == 'wishlist') {
        import('./utils/wishlist.js')
            .then(module => module.wishlist('.prods__wishlist'))
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'product' || pageType == 'blog') {
        if (deviceType == 'computer') {
            import('./utils/zoomCommonImages')
                .then(module => module.handleZoom())
                .catch(error => showErrorInConsole(error.message));
        } else {
            import('./utils/modalCommonImages')
                .then(module => module.handleShowImage())
                .catch(error => showErrorInConsole(error.message));
        }
    }

    showSomeContent();
    setDeviceType();
    search();
    languageMenu();
    lazyLoadImgs();
    toggleHeader();
    validateForm();
    accountFormAddActive();
    runOtherFuncs();
    showAccountPopup();
    dynamicLoadContent('data-ajax-load-content', index_php_key + 'common/footer.loadContent');
});